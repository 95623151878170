export { appPageMode1 };


import { EventBus } from './event-bus.js';
import { throttle } from '../modules/helpers.js'


/*****************************************************************************/
/* 
/* PAGE Mode 1 - Image Layers
/*
/*****************************************************************************/

var appPageMode1 = new Vue ({
    el: '#appPageMode1',
    data: {        
        thisMode: 1,
        sleeping: true,

        layerOrder: [0, 1, 2],

        zoomFactor: 0.25,
        maxZoom: 0.5,

        canvasWidth: 10000,
        canvasHeight: 6000,

        layerData: AppSettings.layers,
        layers: [],

        tilesBaseUrl: AppSettings.tilesBaseUrl,

        lazyLoadInstance: null,
        scrollBooster: null,

        throttledResizeHandler: null,

        dragScrollEnabled: false,

        navZoomVisible: false,
        
        classCenterLayers: '',

        scrollPosSnapshot: null,

    },
    beforeMount: function() {
        this.initZoomFactor();
        this.updateMaxZoom();
    },
    mounted: function() {
        this.viewport = this.$el;
        this.content = this.$refs.dummy;
    },
    methods: {
        wakeUp: function(options) {
            var delay = 200;
            if (undefined !== options && options.hasOwnProperty('init') && options.init) {
                delay = 1200;
            }

            this.sleeping = false;

            this.throttledResizeHandler = throttle(this.handleResize, 250);
            window.addEventListener('resize', this.throttledResizeHandler);

            this.lazyLoadInstance = new LazyLoad({
                elements_selector: '.page--mode-1 .lazy-load',
            });


            this.refreshLayers();
            this.initScrollPosition();

            var self = this;
            window.setTimeout(function() {
                self.navZoomVisible = true;
            }, delay);
        },
        sleep: function() {
            window.removeEventListener('resize', this.throttledResizeHandler);

            if (this.lazyLoadInstance) {
                this.lazyLoadInstance.destroy();;
                this.lazyLoadInstance = null;
            }
            if (this.scrollBooster) {
                this.scrollBooster.destroy();
                this.scrollBooster = null;
            }
            this.layers = [];

            this.navZoomVisible = false;

            var viewport = this.$el;
            this.scrollPosSnapshot = {
                x: viewport.scrollLeft,
                y: viewport.scrollTop,
            };

            var self = this;
            window.setTimeout(function() {
                self.sleeping = true;
            }, 500);
        },

        initZoomFactor: function() {
            if (window.innerWidth > 1500 || window.innerHeight > 900) {
                this.zoomFactor = 0.5;
            } else {
                this.zoomFactor = 0.25;
            }
        },
        updateMaxZoom: function() {
            if (window.innerWidth > 2000) {
                this.maxZoom = 1;
            } else {
                this.maxZoom = 0.5;
            }
        },

        initScrollPosition: function() {
            var self = this;
            window.setTimeout(function() {
                var viewport = self.$el;
                var content = self.$refs.dummy;

                var scrollPos = self.scrollPosSnapshot || {
                    x: (content.scrollWidth -  window.innerWidth) / 2,
                    y: (content.scrollHeight -  window.innerHeight) / 2,
                };

                viewport.scrollTop = scrollPos.y;
                viewport.scrollLeft = scrollPos.x;

                if (!self.isTouchDevice() && typeof ScrollBooster !== 'undefined') {
                    self.scrollBooster = new ScrollBooster({
                        viewport: viewport,
                        content: content,
                        scrollMode: 'native',
                    });
                    self.scrollBooster.setPosition(scrollPos);
            
                    self.dragScrollEnabled = true;
                }
            }, 0);
        },
        resetScrollPosition: function(zoomDelta) {
            var viewport = this.$el;
            var content = this.$refs.dummy;

            var oldCenter = {
                x: viewport.scrollLeft + window.innerWidth / 2,
                y: viewport.scrollTop + window.innerHeight / 2,
            }

            var scrollPos = {
                x: oldCenter.x * zoomDelta - window.innerWidth / 2,
                y: oldCenter.y * zoomDelta - window.innerHeight / 2,
            }


            if (this.scrollBooster) {
                this.scrollBooster.destroy();
            }

            var self = this;
            window.setTimeout(function() {
                viewport.scrollTop = scrollPos.y;
                viewport.scrollLeft = scrollPos.x;

                // reset ScrollBooster drag scroll lib
                if (!self.isTouchDevice() && typeof ScrollBooster !== 'undefined') {
                    self.scrollBooster = new ScrollBooster({
                        viewport: viewport,
                        content: content,
                        scrollMode: 'native',
                    });
                    self.scrollBooster.setPosition(scrollPos);
                }
            }, 0);
        },

        updateLayerOrder: function(layers) {
            for (var order = 0; order < layers.length; order++) {
                var index = layers[order];
                this.$set(this.layerOrder, index, order);
            }
        },
        getIndexForOrder: function(order) {
            return this.layerOrder.findIndex(function(orderInArray) { return orderInArray === order });
        },
        
        classLayerOrder: function(layerIndex) {
            return 'order-' + this.layerOrder[layerIndex];
        },
        styleImage: function(layerIndex, image) {
            var left = 'left: '+ image.x * this.zoomFactor +'px;';
            var top = 'top: '+ image.y * this.zoomFactor +'px;';

            var width= 'width: '+ image.w * this.zoomFactor +'px;';
            var height = 'height: '+ image.h * this.zoomFactor +'px;';

            return left + top + width + height;
        },
        
        imageTiles: function(image) {
            var screenDimension = Math.max(image.w, image.h) * this.zoomFactor;
            var level = Math.ceil(Math.log2(screenDimension));

            var path = this.getTilePath(image, level);

            var tiles = this.getTiles(image, level).map(function(tile) {
                tile.src = path + tile.src;
                return tile;
            });

            return tiles;
        },
        getTilePath: function(image, level) {
            var filename = image.src;
            var cleanFilename = filename.split('-').join('_');
            var src = this.tilesBaseUrl + filename +'/'+ cleanFilename +'_files/'+ level +'/';

            return src;
        },
        getTiles: function(image, level) {
            var tileSize = 256;
            var tiles = [];
            
            var maxDimension = Math.max(image.w, image.h);
            var maxLevel = Math.ceil(Math.log2(maxDimension));

            var scale = 1 / Math.pow(2, maxLevel - level);

            var scaledWidth = image.w * scale;
            var scaledHeight = image.h * scale;

            var numTilesX = Math.ceil(scaledWidth / tileSize);
            var numTilesY = Math.ceil(scaledHeight / tileSize);

            for (var y = 0; y < numTilesY; y++) {
                for (var x = 0; x < numTilesX; x++) {
                    var width = (x == numTilesX - 1) ? scaledWidth % tileSize : tileSize;
                    width += (x == numTilesX - 1 || 0 === x) ? 1 : 2;

                    var height = (y == numTilesY - 1) ? scaledHeight % tileSize : tileSize;
                    height += (y == numTilesY - 1 || 0 === y) ? 1 : 2;

                    tiles.push({
                        src:  x + '_' + y + '.' + image.type,
                        width: width,
                        height: height,
                        firstCol: (0 === x),
                        firstRow: (0 === y),
                    });
                }
            }
            return tiles;
        },
        styleTile: function(tile) {
            var width = 'width: ' + tile.width + 'px;'
            var height = 'height: ' + tile.height + 'px;'
            var marginLeft = (!tile.firstCol) ? 'margin-left: -2px;' : '';
            var marginTop = (!tile.firstRow) ? 'margin-top: -2px;' : '';

            return width + height + marginLeft + marginTop;
        },
        imageTitle: function(layerIndex, imageIndex) {
            return 'image '+ ['A', 'B', 'C'][layerIndex] + imageIndex;
        },
        zoomIn: function() {
            if (this.canZoomIn) {
                this.zoom(2);
            }
        },
        zoomOut: function() {
            if (this.canZoomOut) {
                this.zoom(1 / 2);
            }
        },
        zoom: function(zoomDelta) {
            this.zoomFactor *= zoomDelta;
            this.refreshLayers();
            this.resetScrollPosition(zoomDelta);
            this.updateLayerCentering();
        },
        refreshLayers: function() {
            this.layers =[[], [], []];
            var self = this;
            window.setTimeout(function(order) {
                self.layers = self.layerData;
                self.$nextTick(function() {
                    self.lazyLoadInstance.update();
                });
            }, 10);
        },
        updateLayerCentering: function() {
            var cls = '';

            var wider = window.innerWidth > this.canvasWidth * this.zoomFactor;
            var higher = window.innerHeight > this.canvasHeight * this.zoomFactor;

            if (wider || higher) {
                cls = 'center-layers';

                if (wider) {
                    cls += ' center-layers--horizontal';
                }

                if (higher) {
                    cls += ' center-layers--vertical';
                }
            }

            this.classCenterLayers = cls;
        },
        handleResize: function() {
            this.updateLayerCentering();
            this.updateMaxZoom();
        },

        // inspired by: https://www.labnol.org/code/19616-detect-touch-screen-javascript
        isTouchDevice: function() {
            return (('ontouchstart' in window)
                 || (navigator.MaxTouchPoints > 0)
                 || (navigator.msMaxTouchPoints > 0));
        },

        classZoomDisabled: function(zoomName) {
            switch (zoomName) {
                case 'zoomIn':
                    return !this.canZoomIn ? 'disabled' : '';
                case 'zoomOut':
                    return !this.canZoomOut ? 'disabled' : '';
                default:
                    return '';
            }
        },
    },
    computed: {
        styleLayerSize: function() {
            var width = 'width: '+ this.canvasWidth * this.zoomFactor +'px;';
            var height = 'height: '+ this.canvasHeight * this.zoomFactor +'px;';
            return width + height;
        },
        canZoomIn: function() {
            return this.zoomFactor < this.maxZoom;
        },
        canZoomOut: function() {
            return this.zoomFactor > 0.0625;
        },

        classNavZoomVisible: function() {
            return this.navZoomVisible ? 'visible' : '';
        },
    },
    watch: {
        zoomFactor: function(newZoom, oldZoom) {
            // console.log('zoom: ' + this.zoomFactor);
        },
    }
});
