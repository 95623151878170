export { ImagePreloader };


import { noop } from '../modules/helpers.js'


/*****************************************************************************/
/* 
/* IMAGE PRELOADER
/*
/*****************************************************************************/

var ImagePreloader = function() {};

ImagePreloader.prototype.preloadImage = function(srcset, src, sizes) {
    return new Promise(function(resolve, reject) {
        var image = new Image();
        image.onload = resolve;
        image.onerror = resolve;
        image.sizes = sizes;
        image.srcset = srcset;
        image.src = src;
    });
}

ImagePreloader.prototype.getTimeout = function(delay) {
    return new Promise(function(resolve, reject) {
        window.setTimeout(resolve, delay);
    });
}

ImagePreloader.prototype.preload = function(image, callback) {
    var callback = callback || noop;
    var queue = [];

    this.preloadImage(image.srcset, image.src, image.sizes)
        .then(callback)
        .catch(function(err) {console.log(err)});
}

ImagePreloader.prototype.preloadSet = function(images, callback) {
    var callback = callback || noop;
    var queue = [];

    images.forEach(function(image) {
        queue.push(this.preloadImage(image.srcset, image.src, image.sizes));
    }, this);

    Promise.all(queue)
        .then(callback)
        .catch(function(err) {console.log(err)});
}
