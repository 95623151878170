export { Router };


import { EventBus } from '../apps/event-bus.js';
import { pad } from './helpers.js';


/*****************************************************************************/
/* 
/* ROUTER
/*
/*****************************************************************************/

var Router = function() {
    this.TYPE_CARD = 'card';
    this.TYPE_MODE = 'mode';
    this.TYPE_404 = '404';

    this.type = this.TYPE_MODE;
    this.activeMode = 1;
    this.imgSetIdx = 0;
    this.imageIdx = 0;
    this.cardName = '';

    this.init();

}

Router.prototype.init = function() {
    window.addEventListener('popstate', function() {this.handleHistoryNav()}.bind(this));
    
    this.initFromUrl();
    this.replaceState();
}


Router.prototype.handleHistoryNav = function() {
    var state = history.state;
    this.setState(state);
    EventBus.$emit('router:history-nav', state);
}


Router.prototype.changeMode = function(modeNumber) {
    this.type = this.TYPE_MODE;
    this.activeMode = modeNumber;
    this.pushState();
};

Router.prototype.changeImage = function(imgSetIdx, imageIdx) {
    this.imgSetIdx = imgSetIdx;
    this.imageIdx = imageIdx;
    this.replaceState();
}


Router.prototype.pushState = function() {
    var state = this.getState();
    var title = this.getTitle();
    var path = this.getPath();

    history.pushState(state, title, path);
    document.title = title;
}

Router.prototype.replaceState = function() {
    var state = this.getState();
    var title = this.getTitle();
    var path = this.getPath();

    document.title = title;
    history.replaceState(state, title, path);
}


Router.prototype.getState = function() {
    return {
        type: this.type,
        modeNumber: this.activeMode,
        imgSetIdx: this.imgSetIdx,
        imageIdx: this.imageIdx,
        cardName: this.cardName,
    }
}

Router.prototype.setState = function(state) {
    this.type = state.type;
    this.activeMode = state.modeNumber;
    this.imgSetIdx = state.imgSetIdx;
    this.imageIdx = state.imageIdx;
    this.cardName = state.cardName;
}


Router.prototype.getTitle = function() {
    var title = AppSettings.baseTitle;
    if (2 == this.activeMode) {
        var modeTitle = AppSettings.modeTitles[this.activeMode - 1];
        title = modeTitle + ' | ' + title;
    }
    if (3 == this.activeMode) {
        var imgSetTitle = AppSettings.imageSetsMode3[this.imgSetIdx].title;
        title = imgSetTitle + ' | ' + title;
    }

    return title;
}

Router.prototype.getPath = function() {
    var path = '/';
    if (1 != this.activeMode) {
        var modeName = AppSettings.modeNames[this.activeMode - 1];
        path += modeName + '/';
    }

    if (3 == this.activeMode) {
        path += AppSettings.imageSetsMode3[this.imgSetIdx].slug + '/';
        path += pad(this.imageIdx + 1, 2) + '/';
    }

    return path;
}

Router.prototype.initFromUrl = function() {
    var urlSegments = AppSettings.urlSegments;
    var modeNames = AppSettings.modeNames;

    switch (urlSegments[0]) {
        case 'card':
            this.type = this.TYPE_CARD;
            this.cardName = urlSegments[1];
            break;
        case '':
            this.type = this.TYPE_MODE;
            this.activeMode = 1;
            break;
        case modeNames[1]:
            this.type = this.TYPE_MODE;
            this.activeMode = 2;
            break;
        case modeNames[2]:
            var imgSetIdx = 0;
            var imageIdx = 0;
            if ('' !== urlSegments[1]) {
                var imgSets = AppSettings.imageSetsMode3;
                imgSetIdx = imgSets.findIndex(function(imgSet) { return imgSet.slug === urlSegments[1] });
                if (-1 === imgSetIdx) {
                    imgSetIdx = 0;
                } else {
                    var match = urlSegments[2].match(/^(\d+)/);
                    if (match) {
                        imageIdx = match[1] - 1;
                    }
                }
            }

            this.type = this.TYPE_MODE;
            this.activeMode = 3;
            this.imgSetIdx = imgSetIdx;
            this.imageIdx = imageIdx;
            break;
        default:
            this.type = this.TYPE_404;
            break;
    }
}
