
import { disablePinchZoom, isIE } from './modules/helpers.js';

import { EventBus } from './apps/event-bus.js';

import { Router } from './modules/router.js';

import { appCards } from './apps/cards.js';

import { appPageMode1 } from './apps/mode-1.js';
import { appPageMode2 } from './apps/mode-2.js';
import { appPageMode3 } from './apps/mode-3.js';

import { appTransitionImage } from './apps/transition-image.js';

import { appHeader } from './apps/header.js';



var appMain = new Vue({
    data: {
        header: appHeader,

        mode1: appPageMode1,
        mode2: appPageMode2,
        mode3: appPageMode3,

        currentMode: null,

        cards: appCards,
        
        transition: appTransitionImage,

        router: null,

    },
    created: function() {
        EventBus.$on('router:history-nav', this.historyNav);

        EventBus.$on('header:change-mode', this.changeMode);
        EventBus.$on('header:show-card', this.showCard);
        EventBus.$on('header:update-layer-order', this.updateLayerOrder);

        EventBus.$on('mode2:click-image', this.clickImage);
        EventBus.$on('mode3:change-image', this.changeImage);

        this.router = new Router();
        
        this.init();
    },
    methods: {
        init: function() {
            disablePinchZoom();
            this.setInitialState();
        },
        setInitialState: function() {
            if (isIE()) {
                this.header.wakeUp(0);
                this.showNoIEWarning();
                return;
            }

            var route = this.router.getState();
            switch (route.type) {
                case this.router.TYPE_CARD:
                    this.mode1.wakeUp({ init: true });
                    this.currentMode = this.mode1;
                    this.header.wakeUp(1);
                    this.cards.showCard('home');
                    this.cards.showCard(route.cardName);
                    break;
                case this.router.TYPE_MODE:
                    switch (route.modeNumber) {
                        case 1:
                            this.mode1.wakeUp({ init: true });
                            this.currentMode = this.mode1;
                            this.header.wakeUp(1);
                            break;
                        case 2:
                            this.mode2.wakeUp();
                            this.currentMode = this.mode2;
                            this.header.wakeUp(2);
                            break;
                        case 3:
                            this.mode3.wakeUp({
                                imgSetIdx: route.imgSetIdx,
                                imageIdx: route.imageIdx,
                            });
                            this.currentMode = this.mode3;
                            this.header.wakeUp(3);
                            break;
                    }
                    break;
                case this.router.TYPE_404:
                    this.header.wakeUp(0);
                    break;
            }
        },
        changeMode: function(modeNumber) {
            switch (modeNumber) {
                case 1:
                    this.mode1.wakeUp();
                    this.currentMode.sleep();
                    this.currentMode = this.mode1;
                    break;
                case 2:
                    if (this.mode3 != this.currentMode) {
                        this.mode2.wakeUp();
                        this.currentMode.sleep();
                        this.currentMode = this.mode2;
                    } else {
                        var imageSrc = this.mode3.getCurrentImageSrc();
                        var route = this.router.getState();
                        var imgSetIdx = route.imgSetIdx;
                        var imageIdx = route.imageIdx;
    
                        this.currentMode = this.mode2;

                        var self = this;

                        window.setTimeout(function() {
                            self.mode3.hide();
                        }, 10);

                        window.setTimeout(function() {
                            self.mode2.wakeUp({
                                imgSetIdx: imgSetIdx,
                                imageIdx: imageIdx,
                            });
                        }, 300);

                        this.transition.transitionMode3to2(imageSrc, imgSetIdx, imageIdx)
                            .then(function() {
                                self.mode3.sleep();
                                window.setTimeout(function() {
                                    self.transition.hide();
                                }, 300);

                            });
                    }
                    break;
                case 3:
                    this.mode3.wakeUp();
                    this.currentMode.sleep();
                    this.currentMode = this.mode3;
                    break;
            }

            this.cards.hideAllCards();
            this.header.changeMode(modeNumber);
            this.router.changeMode(modeNumber);

        },
        historyNav: function(route) {
            this.header.changeMode(route.modeNumber);

            switch (route.type) {
                case this.router.TYPE_CARD:
                    this.mode1.wakeUp();
                    this.currentMode.sleep();
                    this.currentMode = this.mode1;
                    this.cards.showCard('home');
                    this.cards.showCard(route.cardName);
                    break;
                case this.router.TYPE_MODE:
                    this.cards.hideAllCards();
                    switch (route.modeNumber) {
                        case 1:
                            this.mode1.wakeUp();
                            this.currentMode.sleep();
                            this.currentMode = this.mode1;
                            break;
                        case 2:
                            this.mode2.wakeUp();
                            this.currentMode.sleep();
                            this.currentMode = this.mode2;
                            break;
                        case 3:
                            this.mode3.wakeUp({
                                imgSetIdx: route.imgSetIdx,
                                imageIdx: route.imageIdx,
                            });
                            this.currentMode.sleep();
                            this.currentMode = this.mode3;
                            break;
                    }
                    break;
            }
        },
        updateLayerOrder: function(layers) {
            this.mode1.updateLayerOrder(layers);
        },
        showCard: function(cardName) {
            this.cards.showCard(cardName);
        },
        changeImage: function(imgSetIdx, imageIdx) {
            this.router.changeImage(imgSetIdx, imageIdx);
        },
        clickImage: function(containerEl, imageSrc, imgSetIdx, imageIdx) {
            this.header.changeMode(3);
            this.router.changeMode(3);
            this.router.changeImage(imgSetIdx, imageIdx);
            this.currentMode = this.mode3;

            this.mode2.hide();
            
            var mode3ImagePreloaded = this.mode3.prepareInitialImage(imgSetIdx, imageIdx);
            var transitionDone = this.transition.transitionMode2to3(containerEl, imageSrc, imgSetIdx, imageIdx);
            
            var self = this;
            Promise.all([mode3ImagePreloaded, transitionDone])
                .then(function() {
                    self.mode3.wakeUp({
                        imgSetIdx: imgSetIdx,
                        imageIdx: imageIdx,
                    });
                    self.mode2.sleep();
                    window.setTimeout(function() {
                        self.transition.hide();
                    }, 300);
                });
        },
        showNoIEWarning: function() {
            var msg = 'Hey there,<br><br>';

            msg += 'looks like you are visiting with Internet Explorer.<br><br>';
            
            msg += 'Here is the thing:<br><br> Microsoft stopped updating the feature set of this particular browser for some time now. ';
            msg += 'All the while its user base keeps shrinking by the day.<br><br>'
                        
            msg += 'So as this site grew more and more ambitious we finally decided to drop support for IE.<br><br>';
            
            msg += 'Please understand and take care!';

            var warning = document.createElement('section');
            warning.classList.add('page--basic-page');

            var article = document.createElement('article');
            article.classList.add('page-content');
            article.innerHTML = msg;

            warning.appendChild(article);

            document.body.appendChild(warning);
        },
    }
});

