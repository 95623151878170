export { Randomizer };


var Randomizer = function(imageSets) {
    this.setCount = imageSets.length;
    this.choices = [];
    this.range = 0;

    this.getSetIdx = function() {
        // first usage -> fill with all indices except last
        if (0 === this.range) {
            this.fillChoicesExcept(this.setCount - 1);
            this.range = this.choices.length;
        }

        if (1 === this.range) {
            // choose remaining setIdx and prepare new choices _without_ this SetIdx
            var setIdx = this.choices[0];
            this.fillChoicesExcept(setIdx);
            this.range = this.choices.length;

        } else {
            // choose randomly -> shrink range
            var choiceIdx = Math.floor(Math.random() * this.range);
            var setIdx = this.choices[choiceIdx];

            this.range -= 1;
            this.choices[choiceIdx] = this.choices[this.range];
        }

        return setIdx;
    }

    this.fillChoicesExcept = function(except) {
        this.choices = [];
        for (var idx = 0; idx < this.setCount; idx++) {
            if (idx !== except) {
                this.choices.push(idx);
            }
        }
    }
}
