export { appPageMode3 };


import { EventBus } from './event-bus.js';
import { ImagePreloader } from '../modules/image-preloader.js';
import { LayoutHelper } from '../modules/layout-helper.js';
import { Velocity } from '../modules/velocity.js';


/*****************************************************************************/
/* 
/* PAGE Mode 3 - Image Slideshow
/*
/*****************************************************************************/

var axis = {
    NONE: 'none',
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
}

var appPageMode3 = new Vue ({
    el: '#appPageMode3',
    data: {
        thisMode: 3,
        sleeping: true,

        isVisible: true,
        metaVisibleOnMobile: false,

        basePath: '',
        mode2BasePath: AppSettings.mode2BasePath,
        
        imageSets: AppSettings.imageSetsMode3,

        currentImageIdx: 0,
        currentImgSetIdx: 0,

        loading: false,

        classImgPos: '',

        slides: [],

        currentSlideIdx: 0,
        leftSlideIdx: 1,
        rightSlideIdx: 2,
        topSlideIdx: 3,
        bottomSlideIdx: 4,

        hiddenSlideIdx: -1,

        loader: null,
        layout: null,
        velocity: null,

        cursorStart: {x: 0, y: 0},
        cursorCurrent: {x: 0, y: 0},

        draggingSpeed: 1.2,
        dragVelocityThreshold: 700,
        dragging: false,
        dragAxis: axis.NONE
    },
    beforeMount: function() {
        this.setBasePath();
        
        this.loader = new ImagePreloader();
        this.layout = new LayoutHelper();
        this.velocity = new Velocity();
    },
    mounted: function() {
    },
    methods: {
        wakeUp: function(options) {
            if (undefined !== options) {
                if (options.hasOwnProperty('imgSetIdx')) {
                    this.currentImgSetIdx = options.imgSetIdx;
                }
                if (options.hasOwnProperty('imageIdx')) {
                    this.currentImageIdx = options.imageIdx;
                }
            }

            this.sleeping = false;
            this.isVisible = true;

            this.initSlides();
            this.updateSlides();

            this.preloadNextImages(3);
        },
        sleep: function() {
            this.slides = [];
            this.sleeping = true;
        },
        hide: function() {
            this.isVisible = false;
        },

        prepareInitialImage: function(imgSetIdx, imageIdx) {
            var self = this;
            return new Promise(function(resolve, reject) {
                var image = self.imageSets[imgSetIdx].images[imageIdx];
                if (!image.hasOwnProperty('loaded')) {
                    self.loader.preload(image, function() {
                        image.loaded = true;
                        resolve('success');
                    });
                } else {
                    resolve('success');
                }
            });
        },

        getCurrentImageSrc: function() {
            return this.$refs.images[this.currentSlideIdx].currentSrc;
        },


        initSlides: function() {
            for (var idx = 0; idx < 5; idx++) {
                    this.slides.push({
                    src: '',
                    srcset: '',
                    sizes: '',
                    classPosition: '',
                    classHidden: '',
                    classLoading: '',
                });  
            }
        },
        setBasePath: function() {
            this.basePath = '/' + AppSettings.modeNames[this.thisMode - 1] + '/';
        },
        updateSlides: function() {
            this.updateSlide(this.currentSlideIdx, this.imgSetIdx(), this.imageIdx());
            this.updateSlide(this.rightSlideIdx, this.imgSetIdx(), this.imageIdx(+1));
            this.updateSlide(this.leftSlideIdx, this.imgSetIdx(), this.imageIdx(-1));

            var bottomImageIdx = this.imageSets[this.imgSetIdx(+1)].lastShownImageIdx;
            this.updateSlide(this.bottomSlideIdx, this.imgSetIdx(+1), bottomImageIdx);

            var topImageIdx = this.imageSets[this.imgSetIdx(-1)].lastShownImageIdx;
            this.updateSlide(this.topSlideIdx, this.imgSetIdx(-1), topImageIdx);

        },
        updateSlide: function(slideIdx, imgSetIdx, imageIdx) {
            var slide = this.slides[slideIdx];

            switch (slideIdx) {
                case this.leftSlideIdx:
                    slide.classPosition = 'left-off';
                    break;
                case this.rightSlideIdx:
                    slide.classPosition = 'right-off';
                    break;
                case this.topSlideIdx:
                    slide.classPosition = 'top-off';
                    break;
                case this.bottomSlideIdx:
                    slide.classPosition = 'bottom-off';
                    break;
                default:
                    slide.classPosition = '';
            }

            slide.classHidden = (slideIdx === this.hiddenSlideIdx) ? 'hidden' : '';
            // time needed for the hidden slide of the three to make its way to the other side 
            window.setTimeout(function() {slide.classHidden = '';}, 100);

            
            var image = this.imageSets[imgSetIdx].images[imageIdx];
            if (image.hasOwnProperty('loaded') && image.loaded) {
                slide.sizes = image.sizes;
                slide.srcset = image.srcset;
                slide.src = image.src;
            } else {
                slide.classLoading = 'loading';
                this.loader.preload(image, function() {
                    slide.sizes = image.sizes;
                    slide.srcset = image.srcset;
                    slide.src = image.src;

                    image.loaded = true;

                    slide.classLoading = '';
                });
            }


            // debug
            if (this.currentSlideIdx === slideIdx) {
                // console.log(this.layout.getMode3ImageSize(image));
            }
        },

        emitChangeImage: function() {
            EventBus.$emit('mode3:change-image', this.currentImgSetIdx, this.currentImageIdx);
        },

        prevImage: function() {
            if (!this.isArrowNavEnabled('prev')) return;

            this.currentImageIdx = this.imageIdx(-1);


            // Slides

            this.hiddenSlideIdx = this.rightSlideIdx;

            this.rightSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.leftSlideIdx;
            this.leftSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.emitChangeImage();

            this.preloadPrevImages(3);
        },
        nextImage: function() {
            if (!this.isArrowNavEnabled('prev')) return;

            this.currentImageIdx = this.imageIdx(+1);


            // Slides

            this.hiddenSlideIdx = this.leftSlideIdx;

            this.leftSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.rightSlideIdx;
            this.rightSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.emitChangeImage();

            this.preloadNextImages(3);
        },
        imageIdx: function(offset) {
            var offset = offset || 0;
            return (this.currentImageIdx + offset + this.imageCount) % this.imageCount;
        },

        preloadPrevImages: function(offset) {
            this.preloadImages(offset, true);
        },
        preloadNextImages: function(offset) {
            this.preloadImages(offset);
        },
        preloadImages: function(imageCount, reverse) {
            var reverse = (undefined === reverse) ? false : reverse;
            var sign = reverse ? -1 : 1;
            
            var preloadImages = [];
            for (var offset = 0; offset < this.imageCount; offset++) {
                var imageIdx = this.imageIdx(sign * offset);
                var image = this.imageSets[this.imgSetIdx()].images[imageIdx];
                if (!image.hasOwnProperty('loaded') || !image.loaded) {
                    preloadImages.push(image);
                }
                if (preloadImages.length == imageCount) {
                    break;
                }
            }
            if (preloadImages.length) {
                this.loader.preloadSet(preloadImages, function() {
                    preloadImages.map(function(img) { img.loaded = true });
                })
            }
        },

        prevImgSet: function() {
            if (!this.isArrowNavEnabled('up')) return;

            this.imageSets[this.currentImgSetIdx].lastShownImageIdx = this.currentImageIdx;
            this.currentImgSetIdx = this.imgSetIdx(-1);
            this.currentImageIdx = this.imageSets[this.currentImgSetIdx].lastShownImageIdx;

            // Slides

            this.hiddenSlideIdx = this.bottomSlideIdx;

            this.bottomSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.topSlideIdx;
            this.topSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.emitChangeImage();

            this.preloadNextImages(3);
        },
        nextImgSet: function() {
            if (!this.isArrowNavEnabled('down')) return;

            this.imageSets[this.currentImgSetIdx].lastShownImageIdx = this.imageIdx();
            this.currentImgSetIdx = this.imgSetIdx(+1);        
            this.currentImageIdx = this.imageSets[this.imgSetIdx()].lastShownImageIdx;

            // Slides

            this.hiddenSlideIdx = this.topSlideIdx;

            this.topSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.bottomSlideIdx;
            this.bottomSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.emitChangeImage();

            this.preloadNextImages(3);
        },
        imgSetIdx: function(offset) {
            var offset = offset || 0;
            return (this.currentImgSetIdx + offset + this.imgSetCount) % this.imgSetCount;
        },

        isArrowNavEnabled: function(direction) {
            switch (direction) {
                case 'up':
                    return this.imageSets[this.imgSetIdx(-1)].hasOwnProperty('images');
                case 'down':
                    return this.imageSets[this.imgSetIdx(+1)].hasOwnProperty('images');
                case 'prev':
                case 'next':
                    return this.imageCount > 0;
            }
        },

        classArrowNavEnabled: function(direction) {
            return this.isArrowNavEnabled(direction) ? 'enabled' : '';
        },


        /* Image Swipe Code starts here */

        // inspired by: https://www.labnol.org/code/19616-detect-touch-screen-javascript
        isTouchDevice: function() {
            return (('ontouchstart' in window)
                 || (navigator.MaxTouchPoints > 0)
                 || (navigator.msMaxTouchPoints > 0));
        },

        getCursor: function(event) {
            if (event.touches && event.touches.length) {
                // touch
                return {
                    x: event.touches[0].pageX,
                    y: event.touches[0].pageY
                }
            }

            if (event.pageX && event.pageY) {
                // mouse
                return {
                    x: event.pageX,
                    y: event.pageY
                };
            }

            return {x: 0, y: 0};
        },

        startDrag: function(event) {
            this.dragging = true;

            this.cursorStart = this.getCursor(event);
            this.cursorCurrent = this.cursorStart;

            this.velocity.reset();

            this.dragAxis = axis.NONE;
        },
        drag: function(event) {
            if (!this.dragging) return;

            this.cursorCurrent = this.getCursor(event);

            if (axis.NONE == this.dragAxis) {
                var lenX = Math.abs(this.cursorCurrent.x - this.cursorStart.x);
                var lenY = Math.abs(this.cursorCurrent.y - this.cursorStart.y);

                if (lenX > lenY) {
                    this.dragAxis = axis.HORIZONTAL;
                } else {
                    this.dragAxis = axis.VERTICAL;
                }
            }

            switch (this.dragAxis) {
                case axis.HORIZONTAL:
                    this.velocity.updatePosition(this.cursorCurrent.x);
                    break;
                case axis.VERTICAL:
                    this.velocity.updatePosition(this.cursorCurrent.y);
                    break;
            }
        },
        stopDrag: function(event) {
            if (!this.dragging) return;

            this.dragging = false;

            var dragVelocity = Math.abs(this.velocity.getVelocity());
            var fastEnough = dragVelocity > this.dragVelocityThreshold;

            switch (this.dragAxis) {
                case axis.HORIZONTAL:
                    var diffX = this.cursorCurrent.x - this.cursorStart.x;
                    var farEnough = Math.abs(diffX) > this.dragDistanceThreshold;
                    if (farEnough || fastEnough) {
                        if (diffX < 0) this.nextImage();
                        if (diffX > 0) this.prevImage();
                    }
                    break;

                case axis.VERTICAL:
                    var diffY = this.cursorCurrent.y - this.cursorStart.y;
                    var farEnough = Math.abs(diffY) > this.dragDistanceThreshold;
                    if (farEnough || fastEnough) {
                        if (diffY < 0) this.nextImgSet();
                        if (diffY > 0) this.prevImgSet();
                    }
                    break;
            }
        },

        styleDragPosition: function(slideIdx) {
            if (!this.dragging) return '';

            switch (this.dragAxis) {
                case axis.HORIZONTAL:
                    var draggingDistance = this.cursorCurrent.x - this.cursorStart.x;
                    draggingDistance *= this.draggingSpeed;
                    switch (slideIdx) {
                        case this.currentSlideIdx:
                            return 'transform: translate('+ draggingDistance +'px, 0)';
                        case this.leftSlideIdx:
                            return 'transform: translate(calc(-100vw + '+ draggingDistance +'px), 0)';
                        case this.rightSlideIdx:
                            return 'transform: translate(calc(100vw + '+ draggingDistance +'px), 0)';
                        default:
                            return '';
                    }

                case axis.VERTICAL:
                    var draggingDistance = this.cursorCurrent.y - this.cursorStart.y;
                    draggingDistance *= this.draggingSpeed;
                    switch (slideIdx) {
                        case this.currentSlideIdx:
                            return 'transform: translate(0, '+ draggingDistance +'px)';
                        case this.topSlideIdx:
                            return 'transform: translate(0, calc(-100vh + '+ draggingDistance +'px))';
                        case this.bottomSlideIdx:
                            return 'transform: translate(0, calc(100vh + '+ draggingDistance +'px))';
                        default:
                            return '';
                    }

                default:
                    return '';
            }
        },

        pad: function(num, size) {
            var s = '00' + num;
            return s.substr(s.length - size);
        },
    },
    computed: {
        classMetaVisibleOnMobile: function() {
            return !this.metaVisibleOnMobile ? 'invisible-on-mobile' : '';
        },
        classIsTouchDevice: function() {
            return this.isTouchDevice() ? 'touch' : '';
        },
        classIsVisible: function() {
            return this.isVisible ? 'visible' : '';
        },
        classDragging: function() {
            return this.dragging ? 'dragging' : '';
        },


        deviceWidth: function() {
            return window.innerWidth;
        },
        dragDistanceThreshold: function() {
            return this.deviceWidth / 5;
        },



        imageCount: function() {
            if (this.imageSets[this.imgSetIdx()].hasOwnProperty('images')) {
                return this.imageSets[this.imgSetIdx()].images.length;
            }

            return 0;
        },
        imgSetCount: function() {
            return this.imageSets.length;
        },

        images: function() {
            if (0 === this.imageCount) return [];

            return this.imageSets[this.imgSetIdx()].images;
        },

        src: function() {
            if (0 === this.imageCount) return '';

            return this.images[this.imageIdx()].src;
        },
        metaList: function() {
            if (0 === this.imageCount) return [];

            return this.images[this.imageIdx()].meta;
        },
    },
});
