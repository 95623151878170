export { appTransitionImage };


import { EventBus } from './event-bus.js';
import { LayoutHelper } from '../modules/layout-helper.js';


/*****************************************************************************/
/* 
/* TRANSITION IMAGE
/*
/*****************************************************************************/

var appTransitionImage = new Vue({
    el: '#appTransitionImage',
    data: {
        isVisible: false,
        src: '',
        stylePosition: '',
        classDest: '',

        layout: null,
    },
    beforeMount: function() {
        this.layout = new LayoutHelper();
    },
    methods: {
        hide: function() {
            this.isVisible = false;
        },

        transitionMode2to3: function(containerEl, imageSrc, imgSetIdx, imageIdx) {
            this.classDest = '';
            this.src = imageSrc;
            
            var left = containerEl.offsetLeft;
            var top = containerEl.offsetTop - window.scrollY;

            this.stylePosition = 'left: '+ left +'px; top: '+ top +'px;';

            this.isVisible = true;

            var self = this;
            window.setTimeout(function() {
                self.classDest = 'dest';
                self.stylePosition = '';
            }, 100);

            return new Promise(function(resolve, reject) {
                window.setTimeout(resolve, 700);
            });
        },
        transitionMode3to2: function(imageSrc, imgSetIdx, imageIdx) {

            var position = this.getTransitionImagePos(imgSetIdx, imageIdx);

            console.log(position);

            this.classDest = 'dest';
            this.src = imageSrc;

            this.stylePosition = '';

            this.isVisible = true;

            var self = this;
            window.setTimeout(function() {
                self.classDest = '';
                self.stylePosition = 'left: '+ position.left +'px; top: '+ position.top +'px;';
            }, 100);

            return new Promise(function(resolve, reject) {
                window.setTimeout(resolve, 700);
            });
        },
        getTransitionImagePos: function(imgSetIdx, imageIdx) {
            var imageSets = AppSettings.imageSetsMode3;
            // get all image sets _before_ the current image set
            var prevImgSets = imageSets.slice(0, imgSetIdx);
            // add image numbers of those sets 
            var flatIdx = prevImgSets.reduce(function(idxAcc, imageSet) { return idxAcc + imageSet.images.length }, 0);
            // add current image index => index into flat list of all images up to current image
            flatIdx += imageIdx;

            var colIdx = flatIdx % this.layout.getColumns();

            return {
                left: this.layout.getGridItemLeft(colIdx),
                top: this.layout.getContentTop(),
            }
        },
    }
});
