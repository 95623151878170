export { appHeader };


import { EventBus } from './event-bus.js';


/*****************************************************************************/
/* 
/* HEADER - Main Navigation
/*
/*****************************************************************************/

var appHeader = new Vue ({
    el: '#appHeader',
    data: {
        modes: [1, 2, 3],
        activeMode: 0,

        imgSetIdx: 0,
        imageIdx: 0,

        layers: [0, 1, 2],
        layerLabels: ['a', 'b', 'c'],

        baseTitle: AppSettings.baseTitle,

        navModesVisible: false,
        navMainVisible: false,
    },
    beforeMount: function() {
    },
    mounted: function() {
    },
    methods: {
        wakeUp: function(modeNumber) {
            this.activeMode = modeNumber;

            var self = this;
            window.setTimeout(function() {
                self.navMainVisible = true;
                if (modeNumber > 0) {
                    self.navModesVisible = true;
                }
            }, 1200);
        },
        changeMode: function(modeNumber) {
            this.activeMode = modeNumber;
        },

        showCard: function(cardName) {
            EventBus.$emit('header:show-card', cardName);
        },
        bringLayerToFront: function(index) {
            var topLayer = this.layers[index];
            this.layers.splice(index, 1);
            this.layers = [topLayer].concat(this.layers);
            EventBus.$emit('header:update-layer-order', this.layers);
        },

        getLayerLabel: function(index) {
            return this.layerLabels[this.layers[index]];
        },

        handleClickMode: function(modeNumber) {
            EventBus.$emit('header:change-mode', modeNumber);
        },

        classNavItem: function(modeNumber) {
            if (this.activeMode === modeNumber && this.activeMode != 1) {
                return 'active';
            }
            return '';
        },
    },
    computed: {
        classNavModesVisible: function() {
            return this.navModesVisible ? 'visible' : '';
        },
        classNavMainVisible: function() {
            return this.navMainVisible ? 'visible' : '';
        },
    }
});
