export { LayoutHelper };


/*****************************************************************************/
/* 
/* LAYOUT HELPER
/*
/*****************************************************************************/

var LayoutHelper = function() {
    this.widthUnit = 34;
    this.widthUnitSmall = this.widthUnit * 0.7;

    this.gridItemAspectRatio = 1.45;

    this.paddingSmall = 15;
};

LayoutHelper.prototype.getColumns = function() {
    var screenWidth = window.innerWidth;

    if (screenWidth > AppSettings.breakpoints.large) {
        return 8;
    }
    if (screenWidth > AppSettings.breakpoints.medium) {
        return 6;
    }
    if (screenWidth > AppSettings.breakpoints.small) {
        return 4;
    }

    return 3;
}

LayoutHelper.prototype.getContentTop = function() {
    if (window.innerWidth > AppSettings.breakpoints.small) {
        return this.widthUnit * 2;
    }

    return this.widthUnit * 3;
}

LayoutHelper.prototype.getLeftMargin = function() {
    if (window.innerWidth > AppSettings.breakpoints.small) {
        return this.widthUnit * 2;
    }
    
    return this.widthUnitSmall;
}

LayoutHelper.prototype.getGutter = function() {
    if (window.innerWidth > AppSettings.breakpoints.small) {
        return this.widthUnit;
    }

    return this.widthUnitSmall;
}

LayoutHelper.prototype.getGridItemWidth = function() {
    return (window.innerWidth - this.getLeftMargin()) / this.getColumns() - this.getGutter();
}

LayoutHelper.prototype.getGridItemHeight = function() {
    return this.getGridItemWidth() * this.gridItemAspectRatio; 
}

LayoutHelper.prototype.getGridItemLeft = function(colIdx) {
    return this.getLeftMargin() + (this.getGridItemWidth() + this.getGutter()) * colIdx;
}

LayoutHelper.prototype.getGridRowTop = function(rowIdx) {
    return (this.getGridItemHeight() + this.getGutter()) * rowIdx;
}



LayoutHelper.prototype.getMode3ImageSize = function(image) {
    var screenWidth = window.innerWidth;
    var screenHeight = window.innerHeight;

    if (screenWidth > AppSettings.breakpoints.small) {
        var minLeft = this.widthUnit * 2;
        var minTop = this.widthUnit * 2;

        var maxWidth = screenWidth - this.widthUnit * 3;
        var maxHeight = screenHeight - this.widthUnit * 4;
    } else {
        var minLeft = this.widthUnitSmall;
        var minTop = this.widthUnit * 4 - 12;

        var maxWidth = screenWidth - this.widthUnitSmall * 2;
        var maxHeight = screenHeight - this.widthUnit * 6;
    }
    
    var containerAspectRatio = maxWidth / maxHeight;

    if (image.aspectRatio > containerAspectRatio) {
        var width = maxWidth;
        var height = width / image.aspectRatio;

        var left = minLeft;
        var top = minTop + (maxHeight - height) / 2;
    } else {
        var height = maxHeight;
        var width = height * image.aspectRatio;

        var top = minTop;
        var left = minLeft + (maxWidth - width) / 2;
    }

    return {
        left: left,
        top: top,
        width: width,
        height: height
    };
}
