export { appCards };


import { EventBus } from './event-bus.js';
import { throttle } from '../modules/helpers.js';


/*****************************************************************************/
/* 
/* CARDS
/*
/*****************************************************************************/

var appCards = new Vue ({
    el: '#appCards',
    data: {
        cards: AppSettings.cards,
        maxZindex: AppSettings.cardsMinZindex,

        dragCardName: '',
        dragActive: false,

        initialX: 0,
        initialY: 0,
        currentX: 0,
        currentY: 0,

        lazyLoadInstance: null,
    },
    beforeMount: function() {

        // for debugging
        // this.cards['exhibitions-and-awards'].visible = true;
        // this.cards['imprint'].visible = true;
        // this.cards['home'].visible = true;
    },
    mounted: function() {
        this.$el.addEventListener('click', this.handleClick);

        window.addEventListener('resize', throttle(this.updateInnerHeight, 250));
        window.addEventListener('orientationchange', this.updateInnerHeight);


        // drag move cards
        this.$el.addEventListener("touchstart", this.dragStart);
        this.$el.addEventListener("touchmove", this.drag);
        this.$el.addEventListener("touchend", this.dragEnd);

        this.$el.addEventListener("mousedown", this.dragStart);
        this.$el.addEventListener("mousemove", this.drag);
        this.$el.addEventListener("mouseup", this.dragEnd);


        this.lazyLoadInstance = new LazyLoad({
            elements_selector: '.card--views .lazy-load',
        });
    },
    methods: {
        showCard: function(cardName) {
            this.bringToFront(cardName);
            
            this.cards[cardName].visible = true;

            this.updateInnerHeight();
            if (this.isSmallScreen() && 'home' != cardName) {
                document.body.style.overflow = "hidden";
                document.body.style.height = "100%";
            }
        },
        clickCloseCard: function(cardName) {
            if ('home' == cardName) {
                this.hideAllCards();
            } else {
                this.hideCard(cardName);
            }
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
        },
        hideCard: function(cardName) {
            this.cards[cardName].visible = false;
            this.cards[cardName].x = 0;
            this.cards[cardName].y = 0;
        },
        hideAllCards: function() {
            for (var cardName in this.cards) {
                this.hideCard(cardName);
            }
            this.maxZindex = AppSettings.cardsMinZindex;
        },
        handleClick: function(event) {
            var el = event.target;
            if ('A' === el.tagName) {
                // click on link to other Card opens other Card
                var href = el.getAttribute('href');
                var match = href.match(/\/card\/([a-z\-]+)\//);
                if (match) {
                    event.preventDefault();
                    var cardName = match[1];
                    this.showCard(cardName);
                }
            } else {
                // click on Card brings Card to front
                var cardEl = el.closest('.card');
                if (cardEl) {
                    var cardName = cardEl.getAttribute('data-name');
                    this.bringToFront(cardName);
                }
            }
        },
        bringToFront: function(cardName) {
            this.cards[cardName].zIndex = this.maxZindex;
            this.maxZindex += 1;
        },
        classVisible: function(cardName) {
            return this.cards[cardName].visible ? '' : 'hidden';
        },
        stylePosAndZ: function(cardName) {
            var position = 'transform: translate3d(' + this.cards[cardName].x + 'px, ' + this.cards[cardName].y + 'px, 0);';
            var zIndex = 'z-index: ' + this.cards[cardName].zIndex + ';';
            return position + zIndex;
        },
        isSmallScreen: function() {
            return window.innerWidth <= AppSettings.breakpoints.small;
        },

        updateInnerHeight: function() {
            var root = document.documentElement;
            root.style.setProperty('--inner-height', window.innerHeight + 'px');
        },



        // drag move cards

        dragStart: function(event) {
            if (this.isSmallScreen()) return;

            var el = event.target;

            if (el.classList.contains('card-title-bar') || el.classList.contains('card-title')) {

                var cardEl = el.closest('.card');
                var cardName = cardEl.getAttribute('data-name');

                if ('home' == cardName) return;

                this.bringToFront(cardName)

                this.dragCardName = cardName;   
            
                var xOffset = this.cards[cardName].x;
                var yOffset = this.cards[cardName].y;

                if ('touchstart' == event.type) {
                    this.initialX = event.touches[0].clientX - xOffset;
                    this.initialY = event.touches[0].clientY - yOffset;
                } else {
                    this.initialX = event.clientX - xOffset;
                    this.initialY = event.clientY - yOffset;
                }

                this.dragActive = true;
            }
        },

        drag: function(event) {

            if (this.dragActive) {
          
                // If the mouse button is not pressed while moving the mouse dragging should be cancled.
                // Otherwise it leads to a card sticking to the mouse and following its every move. 
                // This happens when during dragging the mouse pointer is moved outside the browser window
                // and there (outside) the mouse button is released. In this case no 'mouseup' event is fired.
                //
                // found at: https://stackoverflow.com/questions/322378/javascript-check-if-mouse-button-down#answer-48970682
                var mouseDown = (undefined === event.buttons) ? 1 === event.which : 1 === event.buttons;
                if (!mouseDown) {
                    this.dragEnd();
                    return;
                }

                event.preventDefault();
          
                if ('touchmove' == event.type) {
                    this.currentX = event.touches[0].clientX - this.initialX;
                    this.currentY = event.touches[0].clientY - this.initialY;
                } else {
                    this.currentX = event.clientX - this.initialX;
                    this.currentY = event.clientY - this.initialY;
                }

                this.currentX = Math.max(this.currentX, 0);
                this.currentY = Math.max(this.currentY, 0);

                this.cards[this.dragCardName].x = this.currentX;
                this.cards[this.dragCardName].y = this.currentY;
            }
        },

        dragEnd: function(event) {
            this.dragCardName = '';
            this.dragActive = false;
        }
    },

    computed: {
        classDragging: function() {
            return this.dragActive ? 'dragging' : '';
        },
    },

    watch: {
        dragActive: function() {
            // in Safari when moving the mouse pointer outside the browser window while dragging
            // everything that can be selected will be selected which is super messy
            // --> text selectability is disabled for the whole document while dragging
            if (this.dragActive) {
                document.body.classList.add('unselectable');
            } else {
                document.body.classList.remove('unselectable');
            }
        },
    }
});
