export { appPageMode2 }


import { EventBus } from './event-bus.js';
import { LayoutHelper } from '../modules/layout-helper.js';
import { Randomizer } from '../modules/randomizer.js';
import { throttle, noop, pad } from '../modules/helpers.js'


/*****************************************************************************/
/* 
/* PAGE Mode 2 - Image Grid
/*
/*****************************************************************************/

var order = {
    SORTED: 'sorted',
    RANDOM: 'random'
}

var appPageMode2 = new Vue ({
    el: '#appPageMode2',
    data: {
        thisMode: 2,
        sleeping: true,

        mode3BasePath: '/'+ AppSettings.modeNames[2] +'/',

        isVisible: true,

        imageSets: AppSettings.imageSetsMode2,
        images: [],

        initialImgSetIdx: 0,
        initialImageIdx: 0,

        nextSetIdx: 0,

        itemsOnFirstFetch: 30,
        itemsPerFetch: 20,

        loading: false,

        sortOrder: order.SORTED,
        randomizer: null,

        layout: null,

        lazyLoadInstance: null,
        throttledScrollHandler: null,

        hookClickImage: noop,
    },
    beforeMount: function() {
        this.layout = new LayoutHelper();
    },
    mounted: function() {
        
    },
    methods: {
        wakeUp: function(options) {
            this.initialImgSetIdx = 0;
            this.initialImageIdx = 0;

            if (undefined !== options) {
                if (options.hasOwnProperty('imgSetIdx')) {
                    this.initialImgSetIdx = options.imgSetIdx;
                }
                if (options.hasOwnProperty('imageIdx')) {
                    this.initialImageIdx = options.imageIdx;
                }
            }

            this.sleeping = false;
            this.isVisible =  true;

            this.throttledScrollHandler = throttle(this.handleScroll, 250);
            window.addEventListener('scroll', this.throttledScrollHandler);
            this.lazyLoadInstance = new LazyLoad({
                elements_selector: '.page--mode-2 .lazy-load'
            });

            this.rand = new Randomizer(this.imageSets);

            this.init();
        },
        sleep: function() {
            window.removeEventListener('scroll', this.throttledScrollHandler);

            if (this.lazyLoadInstance) {
                this.lazyLoadInstance.destroy();;
                this.lazyLoadInstance = null;
            }
            this.nextSetIdx = 0;
            this.images = [];
            this.sortOrder = order.SORTED;

            this.rand = null;

            this.sleeping = true;
        },
        hide: function() {
            this.isVisible = false;
        },

        init: function() {
            // get all image sets _before_ the current image set
            var prevImgSets = this.imageSets.slice(0, this.initialImgSetIdx);
            // add image numbers of those sets 
            var flatIdx = prevImgSets.reduce(function(idxAcc, imageSet) {return idxAcc + imageSet.images.length }, 0);
            // add current image index => index into flat list of all images up to current image
            flatIdx += this.initialImageIdx;

            var rowIdx = Math.floor(flatIdx / this.layout.getColumns());
            var scrollTop = this.layout.getGridRowTop(rowIdx);

            var self = this;
            this.fetchImages(flatIdx + this.itemsOnFirstFetch, function() {
                window.setTimeout(function() {
                    window.scrollTo(0, scrollTop);
                    self.lazyLoadInstance.update();
                }, 100);
            });
        },
        fetchImages: function(minImgCount, callback) {
            if (this.loading) return;
            this.loading = true;

            var setsIndices = this.getNextSetsIndices(minImgCount);
                        
            var self = this;
            setsIndices.map(function(imgSetIdx, callback) {
                var images = self.imageSets[imgSetIdx].images;

                for (var imageIdx = 0; imageIdx < images.length; imageIdx++) {
                    var image = images[imageIdx];
                    image.link = self.mode3BasePath +'set-'+ pad(imgSetIdx + 1, 2) +'/image-'+ pad(imageIdx + 1, 2);
                    image.imgSetIdx = imgSetIdx;
                    image.imageIdx = imageIdx;
                    self.images.push(images[imageIdx]);
                }
            });

            callback();

            self.loading = false;
        },
        getNextSetsIndices: function(minImgCount) {
            var setsIndices = [];
            var imgCount = 0;
            while (imgCount < minImgCount) {
                // in first run choose image sets in order
                // after everything was shown once choose image sets randomly
                switch (this.sortOrder) {
                    case order.SORTED:
                        var setIdx = this.nextSetIdx;
                        this.nextSetIdx += 1;

                        if (this.nextSetIdx == this.imageSets.length) {
                            this.sortOrder = order.RANDOM;
                        }
                        break;
                    case order.RANDOM:
                        var setIdx = this.rand.getSetIdx();
                        break;
                }

                setsIndices.push(setIdx);
                imgCount += this.imageSets[setIdx].images.length;
            }
            return setsIndices;
        },

        handleScroll: function() {
            var bottomThresh = Math.max(700, window.innerHeight * 1.4);

            var scrollY = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;
            var distToBottom = document.body.clientHeight - window.innerHeight - scrollY;

            if (distToBottom < bottomThresh) {
                var self = this;
                this.fetchImages(this.itemsPerFetch, function() {
                    self.$nextTick(function() {
                        self.lazyLoadInstance.update();
                    });
                });
            }
        },
        handleClick: function(idx) {
            var containerEl = this.$refs.gridItems[idx];
            var imageSrc = this.$refs.images[idx].currentSrc;

            var image = this.images[idx];
            var imgSetIdx = image.imgSetIdx;
            var imageIdx = image.imageIdx;

            EventBus.$emit('mode2:click-image', containerEl, imageSrc, imgSetIdx, imageIdx);
        },
    },
    computed: {
        classIsVisible: function() {
            return this.isVisible ? 'visible' : '';
        }
    }
});
